<template>
    <nav class="navbar navbar-expand-md menu">
        <div class="container-fluid">
            <div class="navbar-brand">
                <router-link class="d-block d-md-none" :to="{ name: 'Home' }"> <img class="img-fluid d-block mx-auto"
                        src="@/assets/img/logo.png" />
                </router-link>
            </div>
            <button class="navbar-toggler hamburger hamburger--elastic-r" type="button" data-bs-toggle="collapse"
                data-bs-target="#menuCenter" aria-controls="menuCenter" aria-expanded="false"
                aria-label="Toggle navigation">
                <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                </div>
            </button>
            <div class="collapse navbar-collapse justify-content-md-around" id="menuCenter">
                <ul class="navbar-nav d-none d-md-block">
                    <li class="nav-item">
                        <router-link class="" :to="{ name: 'Home' }"> <img class="img-fluid d-block mx-auto"
                                src="@/assets/img/logo.png" />
                        </router-link>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'Home' }" exact>Home
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Produtos
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconBebe' }">Naldecon
                                    Bebê</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconInfantil' }">Naldecon
                                    Infantil</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconAdulto' }">Naldecon
                                    Adulto</router-link>
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Bulas
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconBebeBula' }">Naldecon
                                    Bebê</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconInfantilBula' }">Naldecon
                                    Infantil</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'NaldeconAdultoBula' }">Naldecon
                                    Adulto</router-link>
                            </li>

                        </ul>
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ path: '/', hash: 'onde-encontrar' }"
                            exact>Onde
                            encontrar</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ path: '/', hash: 'contato' }"
                            exact>contato
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link"  active-class="active" :to="{ name: 'Duvidas' }" exact>Dúvidas
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="#">Artigos
                        </a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <router-link class="nav-link" active-class="active"
                            :to="{ name: 'Artigos', paparams: { artigoBusca: this.busca } }" exact>Artigos
                        </router-link>
                    </li> -->
                </ul>
                <div class="d-flex">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                        <input type="text" class="form-control" placeholder="O que procura?"
                            aria-label="O que procura?">
                    </div>
                    <!-- <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                        <input v-model="busca" @change="checkForm" type="text" class="form-control"
                            placeholder="O que procura?" aria-label="O que procura?">
                    </div> -->
                </div>
                <div class="redes">
                    <div class="corrigir-rotacao">
                        <p class="ps-2">CONHEÇA NOSSAS REDES SOCIAIS</p>
                        <ul class="nav justify-content-center">
                            <li class="nav-item"><a href="https://www.facebook.com/NaldeconBrasil/" target="_blank"
                                    rel="facebook"><i class="bi bi-facebook"></i></a>
                            </li>
                            <li class="nav-item"><a href="https://www.instagram.com/naldeconbrasil/" target="_blank"
                                    rel="instagram"><i class="bi bi-instagram"></i></a>
                            </li>
                            <li class="nav-item"><a href="https://www.youtube.com/user/NaldeconBrasil" target="_blank"
                                    rel="youtube"><i class="bi bi-youtube"></i></a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(['isPaginaAtual']),
    },
    data() {
        return {
            busca: ''
        }
    },
    methods: {
        checkForm() {
            store.commit('setBuscaArtigo', this.busca)
            this.busca = ''
            if (this.isPaginaAtual != 'Artigos') {
                this.$router.push({ name: 'Artigos' });
            }
        }
    },
    mounted() {
        var forEach = function (t, o, r) {
            if ("[object Object]" === Object.prototype.toString.call(t))
                for (var c in t)
                    Object.prototype.hasOwnProperty.call(t, c) &&
                        o.call(r, t[c], c, t);
            else
                for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
        };

        var hamburgers = document.querySelectorAll(".hamburger");
        if (hamburgers.length > 0) {
            forEach(hamburgers, function (hamburger) {
                hamburger.addEventListener(
                    "click",
                    function () {
                        this.classList.toggle("is-active");
                    },
                    false
                );
            });
        }
        var largura =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (largura <= 767) {
            var descendentes = document.querySelectorAll("#menuCenter a");
            for (var i = 0; i < descendentes.length; i++) {
                descendentes[i].addEventListener("click", function () {
                    document.getElementById("btn-hamburger").click();
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 0;
    background-color: $cor3;
    box-shadow: 0px 0px 15px 0px $preto;
    height: 95px;

    .input-group {
        .input-group-text {
            background: transparent;
            border-radius: 50px;
            border: 3px solid $cor5;
            border-right: 0;

            i {
                color: $cor5;
            }
        }

        .form-control {
            background: transparent;
            border-radius: 50px;
            border: 3px solid $cor5;
            border-left: 0;
            padding-left: 0;
            color: $branco;

            &::placeholder {
                color: $cor5;
            }
        }

    }

    .navbar-nav {
        li {
            a {
                color: $branco;
                font-family: $font4;
                font-weight: bold;
                font-style: normal;
                font-size: 16px;
                line-height: 20px;
                padding: 9px 15px;
                text-transform: uppercase;
                position: relative;
                white-space: nowrap;

                &::before {
                    content: '|';
                    position: absolute;
                    right: -3px;
                    color: $branco;
                    top: 9px;
                }

                &:hover,
                &:focus {
                    color: $branco;
                }
            }

            .btn-login {
                color: $preto;
                background-color: $branco;
                border-color: $branco;

                &:hover,
                &:focus {
                    color: $preto;
                }
            }
        }

        li a.active {
            color: $branco;
        }

        li:last-child {
            a {
                &::before {
                    content: '';
                }
            }
        }
    }

    .dropdown {
        .dropdown-menu {
            background-color: $cor3;

            .dropdown-item {
                color: $branco;

                &:hover {
                    background-color: $cor1;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    .redes {
        background-color: $cor3;
        position: absolute;
        right: 8%;
        bottom: -62px;
        padding: 0 16px;
        transform: skew(-23deg);
        box-shadow: 0px 7px 9px -6px #000;
        border-radius: 0 0 23px 10px;
        text-align: center;

        .corrigir-rotacao {
            transform: skew(21deg);
        }

        p {
            color: $branco;
            font-family: $font6;
            font-weight: normal;
            font-style: normal;
            font-size: 10px;
            line-height: 15px;
            margin-bottom: 0px;
        }

        .nav {
            li {
                a {
                    background-color: $cor1;
                    margin: 5px;
                    width: 38px;
                    height: 38px;
                    font-size: 22px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: $branco;
                    }
                }
            }
        }

    }

}


@media (max-width: 767.98px) {
    .menu {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        padding: 0;
        height: 55px;

        img {
            max-width: 170px;
            width: 100%;
            padding-left: 15px;
        }

        .input-group {
            padding: 0 15px;
        }

        .redes {
            position: relative;
            bottom: 0;
            right: 0;
            box-shadow: none;
            padding: 10px 8px;
        }

        .container-fluid {
            padding: 0;

            .navbar-collapse {
                background-color: $cor3;
            }
        }

        .navbar-nav {
            li {
                a {

                    font-size: 16px;
                    line-height: 20px;

                    &::before {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        color: $branco;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .menu {
        .navbar-nav li a {
            font-size: 10px;
            padding: 9px 8px;
        }

        .redes {
            right: 2%;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .menu {
        .navbar-nav li a {
            font-size: 10px;
            padding: 9px 8px;
        }

        .redes {
            right: 5%;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .menu {
        .navbar-nav li a {
            font-size: 15px;
            padding: 9px 11px;
        }

        .redes {
            right: 5%;
        }
    }
}

@media (min-width: 1400px) {}
</style>