import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    buscaArtigo: '',
    paginaAtual: '',
    loading: false,
  },

  mutations: {
    setBuscaArtigo(state, value) {
      state.buscaArtigo = value
    },
    setLoading(state, value) {
      state.loading = value
    },

    rotaAtual(state, value) {
      state.paginaAtual = value
    },

  },

  getters: {
    isBuscaArtigo(state) {
      return state.buscaArtigo
    },
    isLoading(state) {
      return state.loading
    },
    isPaginaAtual(state) {
      return state.paginaAtual
    },

  },

  actions: {

  },

})