<template>
  <div>
    <menu-center />
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" loader="dots" :opacity="1"></loading>
  </div>
</template>

<script>
import MenuCenter from '@/components/MenuCenter.vue'

import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { MenuCenter, Loading },

  computed: {
    ...mapGetters(['isLoading']),
  },
}
</script>

<style lang="scss" scoped></style>