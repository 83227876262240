import Vue from 'vue'
import VueRouter from 'vue-router'
import Menu from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Naldecon Febre'

const routes = [{
    path: '/',
    name: 'Home',
    components: {
      default: () => import('../views/Home.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Home',
      requireLogin: false,
    }
  },

  {
    path: '/naldecon-bebe',
    name: 'NaldeconBebe',
    components: {
      default: () => import('../views/NaldeconBebe.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Bebê',
      requireLogin: false,
    }
  },
  {
    path: '/naldecon-bebe-bula',
    name: 'NaldeconBebeBula',
    components: {
      default: () => import('../views/NaldeconBebeBula.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Bebê Bula',
      requireLogin: false,
    }
  },
  {
    path: '/naldecon-infantil',
    name: 'NaldeconInfantil',
    components: {
      default: () => import('../views/NaldeconInfantil.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Infantil',
      requireLogin: false,
    }
  },
  {
    path: '/naldecon-infantil-bula',
    name: 'NaldeconInfantilBula',
    components: {
      default: () => import('../views/NaldeconInfantilBula.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Infantil Bula',
      requireLogin: false,
    }
  },
  {
    path: '/naldecon-adulto',
    name: 'NaldeconAdulto',
    components: {
      default: () => import('../views/NaldeconAdulto.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Adulto',
      requireLogin: false,
    }
  },
  {
    path: '/naldecon-adulto-bula',
    name: 'NaldeconAdultoBula',
    components: {
      default: () => import('../views/NaldeconAdultoBula.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Naldecon Adulto Bula',
      requireLogin: false,
    }
  },

  {
    path: '/faq',
    name: 'Duvidas',
    components: {
      default: () => import('../views/Duvidas.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Dúvidas',
      requireLogin: false,
    }
  },
  {

    path: '/artigos',
    name: 'Artigos',
    components: {
      default: () => import('../views/Artigos.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Artigos',
      requireLogin: false,
    }
  },
  {
    path: '/artigo-detalhe/:artigoId',
    name: 'ArtigoDetalhe',
    components: {
      default: () => import('../views/ArtigoDetalhe.vue'),
      menu: Menu,
      footer: Footer
    },
    props: true,
    meta: {
      title: 'Artigo',
      requireLogin: false,
    }
  },

  {
    path: '/aviso-privacidade',
    name: 'AvisoPrivacidade',
    components: {
      default: () => import('../views/AvisoPrivacidade.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Aviso Privacidade',
      requireLogin: false,
    }
  },
  {
    path: '/politica-cookies',
    name: 'PoliticaCookies',
    components: {
      default: () => import('../views/PoliticaCookies.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Política Cookies',
      requireLogin: false,
    }
  },
  {
    path: '/politica-privacidade',
    name: 'PoliticaPrivacidade',
    components: {
      default: () => import('../views/PoliticaPrivacidade.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Política Privacidade',
      requireLogin: false,
    }
  },
  {
    path: '/termos-uso',
    name: 'TermosUso',
    components: {
      default: () => import('../views/TermosUso.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Termos de Uso',
      requireLogin: false,
    }
  },
  {
    path: '*',
    name: 'NotFound',
    components: {
      default: () => import('../views/NotFound.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Página Não Encontrada',
      requireLogin: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
  })
})



router.beforeEach((to, from, next) => {
  window.speechSynthesis.cancel()
  store.commit('rotaAtual', to.name)
  if (to.meta.requireLogin) {

    next()

  } else {

    next()
  }
  if (to.meta.requireCadastro) {


    next()

  }
  if (to.meta.requirePeriodo) {
    next()
  }
})



export default router