<template>
  <div class="footer text-center">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <a class="nav-link" href="https://www.naldecon.com.br/terms-and-conditions/" target="_blank">Termos de
                  Uso
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.naldecon.com.br/privacy-policy/" target="_blank">política
                  de privacidade</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.naldecon.com.br/cookies/" target="_blank">política de
                  cookies</a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Duvidas' }">Dúvidas
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Home', hash: '#contato' }">Contato
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-12 mt-3">
            <h6>© 2024 RECKITT BENCKISER HEALTH COMERCIAL LTDA - Todos os direitos reservados</h6>
          </div>
          <div class="col-12">
            <p>
              1. Naldecon Febre (paracetamol). MEDICAMENTOS NOTIFICADOS conforme RDC nº 576/2021. AFE nº 1.07390-1.
              Indicado para alívio da febre e dor. SE PERSISTIREM OS SINTOMAS, O MÉDICO DEVERÁ SER CONSULTADO. Jun/24.
              <br>
              <br>
              2. De acordo com a dosagem para analgésicos líquidos a base de paracetamol. Mercado: IQVIA
              Database – FY23. Considerando analgésicos líquidos a base de paracetamol nas concentrações
              32mg/mL e 100mg/mL.
              <br>
              <br>
              3. Brasil. Ministério da Saúde. Secretaria de Vigilância em Saúde e Ambiente. Departamento de
              Doenças Transmissíveis. Dengue - diagnóstico e manejo clínico: adultos e criança – 6. ed. –
              Brasília: Ministério da Saúde, 2024
              <br>
              <br>
              4. Schachtel BP, Thoden WR. Clin Pharmacol Ther. 1993; 53 (5): 593-601; 2. Vauzelle-Kervroeden F
              et al. J Pediatri. 1997; 131 (5): 683-7; 3. Sociedade Brasileira de Pediatria (SBP). Departamentos
              Científicos de Pediatria Ambulatorial e de Infectologia. Manejo da Febre Aguda. 2021; 4.
              Pernerstorfer T et al. Clin Pharmacol Ther. 1999; 66 (1): 51-7; 5. Schachtel BP, Furey SA, Thoden
              WR. J Clin Pharmacol. 1996; 36(12):1120-1125; 6. Mehlisch DR, Frakes LA. Clin Ther. 1984; 7 (1):
              89-97.
              <br>
              <br>
              5. Pradeepkumar BT et al. Int J Basic Clin Pharmacol. 2020;9(3):388-391
              <br>
              <br>
              6. Pernerstorfer T et al. Clin Pharmacol Ther. 1999; 66 (1): 51-7;
              <br>
              <br>
              7. Schachtel BP, Furey SA, Thoden WR. J Clin Pharmacol. 1996; 36(12):1120-1125;
              <br>
              <br>
              8. Mehlisch DR, Frakes LA. Clin Ther. 1984; 7 (1): 89-97.
              <br>
              <br>
              9. Pradeepkumar BT et al. Int J Basic Clin Pharmacol. 2020;9(3):388-391
              <br>
              <br>
              10. Ministério da Saúde. Manual de vigilância epidemiológica de eventos adversos pós-vacinação. 4ª ed. -
              Brasília: Ministério da Saúde, 2021.

            </p>
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  },
  mounted() {

  }
}
</script>



<style lang="scss" scoped>
.footer {
  background-color: $cor3;
  padding: 20px 0 40px;
  border-radius: 35px 35px 0 0;

  .nav-item {
    position: relative;


    a {
      color: $branco;
      font-family: $font6;
      font-weight: normal;
      font-style: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 9px 10px;
      display: inline-block;
      text-transform: uppercase;

      &::before {
        content: '|';
        position: absolute;
        right: -3px;
        color: $branco;
        top: 9px;
      }
    }
  }

  .nav-item:last-child {
    a {
      &::before {
        content: '';
      }
    }
  }

  h6 {

    color: $branco;
    font-family: $font6;
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
  }

  p {

    color: $branco;
    font-family: $font6;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
  }
}


@media (max-width: 767.98px) {
  .footer {
    .nav-item {
      width: 100%;
      text-align: center;

      a {
        &::before {
          content: '';
        }
      }
    }

    p {
      text-align: justify;
      margin-top: 30px;
      padding: 0 0 0 0px;
    }
  }
}
</style>